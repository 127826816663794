import React                   from 'react';
import { PropTypes }           from 'prop-types';
import classNames              from 'classnames';
import { FontAwesomeIcon }     from '@fortawesome/react-fontawesome';

import { SegmentedMessage }    from 'sms-segments-calculator';
import BulkActions             from '~/actions/bulk_actions';
import CampaignPicker          from '~/components/forms/campaign_picker';
import AppModal                from './app_modal';
import APIRequest              from '~/lib/api_request';
import { DOMAIN_MESSAGES }     from '~/helpers/constants';
import { checkFeatureFlag }    from '~/helpers/FeatureFlagChecker';
import CheckSmsUsage           from '~/components/CheckSmsUsage';
import DateTimePicker          from '~/components/forms/DateTimePicker';

const { currentTeam } = Rails.helpers;
const CurrentDate = Moment().startOf('day').toDate();

class AddCampaignModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors:            {},
      loadingBulkLimits: false,
      bulkLimitsReached: false,
      submitting:        false,
      formData:          {
        campaign_start_date:      null,
        subscribe_to_campaign_id: null,
      },
      message: {
        type: null,
        text: '',
      },
      unverifiedNumbersWarning: false,
      loadingValidation:        false,
      isSmsValid:               false,
      isEmailDomainValid:       false,
      warningAccepted:          false,
      segmentsCount:            0,
      textMessagesCount:        0,
      isLimitExceeded:          false,
      selectedCampaignId:       null,
      limitAlertAllowed:        false,
    };
  }

  componentDidMount() {
    this.validateBulkLimits();
    this.setState({
      limitAlertAllowed: checkFeatureFlag(process.env.ALLOW_ALERT_ON_TEXT_LIMIT_FLAG),
    });
  }

  onWarningUpdated = (isLimitExceeded) => {
    this.setState({ isLimitExceeded });
  }

  validateBulkLimits = () => {
    const { countSelectedItems } = this.props;
    const selectedItemsCount = countSelectedItems();

    this.setState({ loadingBulkLimits: true }, () => {
      APIRequest.get({
        resource: '/v1/bulk_actions/validate_limits',
        data:     {
          count_of_contacts: selectedItemsCount,
        },
      }).end((error, response) => {
        this.setState({ loadingBulkLimits: false }, () => {
          if (!error) {
            const {
              bulk_message_frequency_limit,
              bulk_message_size_limit,
              reached_limits,
            } = response.body;

            this.setState({
              bulkLimitsReached:         reached_limits,
              bulkMessageFrequencyLimit: bulk_message_frequency_limit || 'unlimited',
              bulkMessageSizeLimit:      bulk_message_size_limit || 'unlimited',
            });
          }
        });
      });
    });
  };

  validateSmsCampaignStepsWithUnverifiedNumbers = async (campaignId) => {
    const isRestrictionForUnverifiedNumbersEnabled = checkFeatureFlag(process.env.BLOCK_UNVERIFIED_NUMBERS_FLAG);

    const defaultState = {
      unverifiedNumbersWarning: false,
      loadingValidation:        false,
    };

    if (!isRestrictionForUnverifiedNumbersEnabled || !campaignId) {
      this.setState({ ...defaultState, isSmsValid: true });
      return;
    }

    this.setState({ loadingValidation: true });

    try {
      const response = await APIRequest.get({
        resource: `/v1/campaigns/${campaignId}/check_campaign_for_unverified_numbers`,
      });

      const unferifiedNumbersInSteps = response.body?.unferifiedNumbersInSteps;

      if (unferifiedNumbersInSteps) {
        this.setState({
          unverifiedNumbersWarning: true,
          isSmsValid:               false,
        });
      } else {
        this.setState({ ...defaultState, isSmsValid: true });
      }
    } catch (error) {
      this.setState({ ...defaultState, isSmsValid: true });
    } finally {
      this.setState({ loadingValidation: false });
    }
  };

  validateEmailDomainForCampaignSteps = async (campaignId) => {
    const { countSelectedItems } = this.props;
    const selectedItemsCount = countSelectedItems();
    const maxRecipients = parseInt(
      process.env.UNCONFIG_DOMAIN_RECIPIENTS_LIMIT,
      10,
    );

    if (!campaignId) {
      this.setState({
        message: {
          type: null,
          text: '',
        },
        isEmailDomainValid: true,
      });
      return;
    }

    this.setState({ loadingValidation: true });

    try {
      const unconfiguredDomains = await this.fetchDomainsConfiguration(campaignId);

      if (unconfiguredDomains.length > 0) {
        if (selectedItemsCount <= maxRecipients) {
          this.setState({
            message:            DOMAIN_MESSAGES.UNCONFIGURED_DOMAINS_WARNING(unconfiguredDomains),
            isEmailDomainValid: true,
          });
        } else {
          this.setState({
            message: DOMAIN_MESSAGES.UNCONFIGURED_DOMAINS_ERROR(
              selectedItemsCount,
              maxRecipients,
              unconfiguredDomains,
            ),
            isEmailDomainValid: false,
          });
        }
      } else {
        this.setState({
          message: {
            type: null,
            text: '',
          },
          isEmailDomainValid: true,
        });
      }
    } catch (error) {
      this.setState({
        message:            DOMAIN_MESSAGES.VALIDATION_ERROR,
        isEmailDomainValid: false,
      });
    } finally {
      this.setState({ loadingValidation: false });
    }
  };

  fetchDomainsConfiguration = (campaignId) => new Promise((resolve, reject) => {
    const {
      searchData,
      bulkSelecting,
      selectedItems,
      unSelectedItems,
    } = this.props;

    let selectedLeads = Object.values(selectedItems);
    const unselectedLeads = Object.values(unSelectedItems);
    let contactsData;

    if (bulkSelecting) {
      selectedLeads = 'all';
      contactsData = {
        ...searchData,
        ids:            'all',
        unselected_ids: _lodash.map(unselectedLeads, 'id'),
      };
    } else {
      contactsData = {
        ...searchData,
        ids: _lodash.map(selectedLeads, 'id'),
      };
    }

    APIRequest.get({
      resource:
        '/v1/email_domains/validate_domains_configured_for_campaign',
      data: {
        campaign_id: campaignId,
        ...contactsData,
      },
    }).end((error, response) => {
      if (!error) {
        resolve(response.body.unconfigured_emails);
      } else {
        reject(error);
      }
    });
  });

  handleCampaignSelect = (selectedItem) => {
    const { formData } = this.state;
    const campaignId = selectedItem ? selectedItem.value : null;

    if (campaignId && campaignId !== '-blank-') {
      const smsSteps = selectedItem.campaign_steps
        .filter((step) => step.action_type === 'sms');
      const segmentsCount = smsSteps.reduce((acc, step) => acc + new SegmentedMessage(step.body).segmentsCount, 0);
      const textMessagesCount = selectedItem.campaign_steps.filter((step) => step.action_type === 'sms').length;

      this.setState({ segmentsCount, textMessagesCount, selectedCampaignId: campaignId });
    } else {
      this.setState({ segmentsCount: 0 });
    }

    this.setState(
      {
        formData: {
          ...formData,
          subscribe_to_campaign_id: campaignId,
        },
      },
      () => {
        this.validateEmailDomainForCampaignSteps(campaignId);
        this.validateSmsCampaignStepsWithUnverifiedNumbers(campaignId);
      },
    );
  };

  handleStartDateChange = (selectedDate) => {
    const { formData } = this.state;
    const startDate = selectedDate.format('YYYY-MM-DD');

    this.setState({
      formData: { ...formData, campaign_start_date: startDate },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.validate().then(
      () => {
        const $modal = $(this.appModal.modal);

        this.setState({ submitting: true }, () => {
          const { formData } = this.state;
          const {
            searchData,
            bulkSelecting,
            selectedItems,
            unSelectedItems,
          } = this.props;

          let selectedLeads = Object.values(selectedItems);
          const unselectedLeads = Object.values(unSelectedItems);
          let campaignData;

          if (bulkSelecting) {
            selectedLeads = 'all';
            campaignData = {
              ...searchData,
              ids:            'all',
              unselected_ids: _lodash.map(unselectedLeads, 'id'),
            };
          } else {
            campaignData = {
              ...searchData,
              ids: _lodash.map(selectedLeads, 'id'),
            };
          }

          APIRequest.put({
            resource: '/v1/lead_bulk_actions',
            data:     { ...campaignData, ...formData },
          }).end((error) => {
            this.setState({ submitting: false }, () => {
              if (!error) {
                const { countSelectedItems, reloadLeads } = this.props;
                const selectedItemsCount = countSelectedItems();

                GlobalContainer.notify(
                  `${selectedItemsCount} agents are subscribed to the campaign`,
                  'success',
                );

                $modal.modal('hide');

                BulkActions.createAction('email');

                reloadLeads();
              }
            });
          });
        });
      },
      (_errors) => {},
    );
  };

  validate = () => new Promise((resolve, reject) => {
    const { formData } = this.state;
    const VALIDATABLE_FIELDS = [
      'subscribe_to_campaign_id',
      'campaign_start_date',
    ];

    const errors = {};
    VALIDATABLE_FIELDS.forEach((field) => {
      if (!formData[field]) errors[field] = "Can't be empty";
    });

    this.setState({ errors });

    if (!Object.keys(errors).length) {
      resolve();
    } else {
      reject(errors);
    }
  });

  renderBody = () => {
    const { bulkLimitsReached, loadingBulkLimits } = this.state;

    if (loadingBulkLimits) {
      return (
        <div className="text-center p40">
          <FontAwesomeIcon
            icon="far fa-spinner"
            pulse
            className="fa-lg"
          />
        </div>
      );
    }

    if (bulkLimitsReached) {
      return this.renderBulkVallidationError();
    }

    return this.renderContent();
  };

  resetWarning = (selectedOption) => {
    const { selectedCampaignId } = this.state;

    if (selectedOption && selectedOption.value !== selectedCampaignId) {
      this.setState({ warningAccepted: false, isLimitExceeded: false });
    }
  }

  acceptWarning = () => {
    this.setState({ warningAccepted: true });
  }

  renderBulkVallidationError = () => {
    const { bulkMessageFrequencyLimit, bulkMessageSizeLimit } = this.state;
    const bulkMessageLookbackPeriod = process.env.BULK_MESSAGE_LOOKBACK_PERIOD / 24;

    const trialText = (
      <>
        while on a trial. Your limits will be increased at the end of
        the trial to those of your selected pricing plan seen
        {' '}
        <a href="/billing" target="_blank" rel="noopener noreferrer" className="alert-link">
          here.
        </a>
      </>
    );

    const mainText = (
      <>
        on your pricing plan. Please upgrade your pricing plan
        {' '}
        <a href="/billing" target="_blank" rel="noopener noreferrer" className="alert-link">
          here
        </a>
        {' '}
        to increase your limits.
      </>
    );

    return (
      <div className="p20 text-center">
        <div className="alert alert-danger" role="alert">
          Bulk messaging actions can only be triggered on up to
          {' '}
          {bulkMessageSizeLimit}
          {' '}
          contacts up to
          {' '}
          {bulkMessageFrequencyLimit}
          {' '}
          times within
          {' '}
          {bulkMessageLookbackPeriod}
          {' '}
          days
          {' '}
          {currentTeam.trial ? trialText : mainText}
        </div>
      </div>
    );
  };

  renderContent = () => {
    const {
      errors,
      submitting,
      message,
      loadingValidation,
      isSmsValid,
      isEmailDomainValid,
      unverifiedNumbersWarning,
      warningAccepted,
      textMessagesCount,
      segmentsCount,
      isLimitExceeded,
      selectedCampaignId,
      limitAlertAllowed,
    } = this.state;
    const { countSelectedItems } = this.props;
    const selectedItemsCount = countSelectedItems();
    const saveButtonClass = classNames('btn btn-primary', {
      disabled: submitting || loadingValidation || !isEmailDomainValid || !isSmsValid,
    });

    return (
      <>
        <div className="modal-body">
          <CheckSmsUsage
            segmentsCount={segmentsCount}
            selectedItemsCount={selectedItemsCount}
            onWarningAccepted={this.acceptWarning}
            textMessagesCount={textMessagesCount}
            onWarningUpdated={this.onWarningUpdated}
            campaignId={selectedCampaignId}
          />
          {message.text && (
            <div
              className={`alert alert-${message.type} mb15`}
              role="alert"
            >
              <h6>
                {message.text}
                {' '}
                <a
                  href="/team/edit?tab=email_settings"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Configure Now
                </a>
              </h6>
            </div>
          )}

          {unverifiedNumbersWarning && (
            <div
              className="alert alert-warning mb15"
              role="alert"
            >
              Your campaign includes a text step, but the sender&apos;s
              phone number is not linked to an approved verified texting use case.
              To assign this campaign, please select a phone number in the
              text step(s) which are linked to an approved verified texting use case.
            </div>
          )}

          <div className="form-row">
            <div className="col">
              <CampaignPicker
                className={
                  errors.subscribe_to_campaign_id ? 'has-error' : ''
                }
                countSelectedItems={countSelectedItems}
                onChange={this.handleCampaignSelect}
                resetWarning={this.resetWarning}
                placeholder="Select a Campaign..."
              />
            </div>

            <div className="col">
              <DateTimePicker
                id="campaign_start_date"
                placeholder="Select Start Date"
                format="LL"
                pickerType="date"
                minDate={CurrentDate}
                isInvalid={errors.campaign_start_date}
                onChange={this.handleStartDateChange}
              />
            </div>
          </div>

          <div className="text-warning mt-3  ">
            The campaign will not be assigned to contacts where the
            same campaign is currently running and the timelines
            overlap.
          </div>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="submit"
            className={saveButtonClass}
            disabled={
              submitting || loadingValidation || !isEmailDomainValid || !isSmsValid || (limitAlertAllowed && isLimitExceeded && !warningAccepted)
            }
          >
            Add
          </button>
        </div>
      </>
    );
  };

  render() {
    const { submitting } = this.state;
    const { countSelectedItems } = this.props;
    const selectedItemsCount = countSelectedItems();
    const spinnerImg = (
      <FontAwesomeIcon icon="far fa-spinner" pulse />
    );

    return (
      <AppModal
        ref={(appModal) => (this.appModal = appModal)}
        dialogClass="modal-dialog modal-lg"
      >
        <form method="POST" onSubmit={this.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="appModalLabel">
              Add
              {' '}
              <small>
                {selectedItemsCount}
                {' '}
                agents
              </small>
              {' '}
              to
              Campaign
              {' '}
              {submitting && spinnerImg}
            </h5>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          {this.renderBody()}
        </form>
      </AppModal>
    );
  }
}

AddCampaignModal.defaultProps = {
  bulkSelecting:      false,
  countSelectedItems: () => {},
  records:            {},
  searchData:         {},
  selectedItems:      {},
  table:              {},
  unSelectedItems:    {},
};

AddCampaignModal.propTypes = {
  bulkSelecting:      PropTypes.bool,
  countSelectedItems: PropTypes.func,
  records:            PropTypes.shape({}),
  searchData:         PropTypes.shape({}),
  selectedItems:      PropTypes.shape({}),
  table:              PropTypes.shape({}),
  unSelectedItems:    PropTypes.shape({}),
};

export default AddCampaignModal;
