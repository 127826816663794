import autoBind           from 'react-autobind';
import classNames         from 'classnames';
import React              from 'react';
import { v4 as uuidv4 }   from 'uuid';
import CountUp            from 'react-countup';
import NumberFormat       from 'react-number-format';
import bugsnag            from '@bugsnag/js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import APIRequest            from '~/lib/api_request';
import AdditionalUsersSelect from './forms/additional_users_select';
import SmsStats              from './pages/billing/sms_stats';
import EmailStats            from './pages/billing/email_stats';
import CallStats             from './pages/billing/call_stats';
import RecordingStats        from './pages/billing/recording_stats';
import CardForm              from './forms/billing_forms/card_form';
import UpdateCardForm        from './forms/billing_forms/update_card_form';

const bulkMessageLookbackPeriod = process.env.BULK_MESSAGE_LOOKBACK_PERIOD / 24;

class BillingApp extends React.Component {
  constructor(props) {
    super(props);

    const { subscription } = this.props;

    this.state = {
      subscription,
      view:                 subscription ? 'subscription' : 'plans',
      hasError:             false,
      chargedCard:          false,
      updatedCard:          false,
      annual:               true,
      frequency:            'yearly',
      loading:              true,
      qty_additional_users: subscription ? subscription.qty_additional_users : 0,
    };

    autoBind(this);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    APIRequest.get({
      resource: '/v1/pricing_plans',
    }).end((err, res) => {
      this.setState({ plans: res.body, loading: false });
    });
  }

  componentDidCatch(error, errorInfo) {
    if (!process.env.BUGSNAG_API_KEY) return;
    const bugsnagClient = bugsnag(process.env.BUGSNAG_API_KEY);

    if (bugsnagClient) {
      bugsnagClient.notify(error, { context: errorInfo });
    }
  }

  onSuccess(res) {
    this.setState({
      subscription: res.body,
      chargedCard:  true,
      view:         'subscription',
    });
  }

  onSuccessPaymentCard(res) {
    this.setState({
      subscription: res.body,
      updatedCard:  true,
      view:         'subscription',
    });
  }

  onCancel(e) {
    this.setState({ view: 'plans' });
  }

  onChoosePlan(plan) {
    const { qty_additional_users } = this.state;

    this.setState({
      invoice:                  null,
      pricing_plan_id:          plan.id,
      plan_id:                  plan.plan_id,
      additional_users_plan_id: plan.users_plan_id,
      qty_additional_users,
      view:                     'cardform',
    });
  }

  onChooseAdditionalUsers(e) {
    const { subscription, qty_additional_users, plans } = this.state;

    this.setState({
      invoice:                  null,
      pricing_plan_id:          subscription.pricing_plan_id,
      plan_id:                  subscription.base_plan_stripe_id,
      additional_users_plan_id: subscription.additional_users_stripe_id,
      qty_additional_users,
      view:                     'cardform',
    });
  }

  handleQtyAdditionalUsersSelect = (selectedValue) => {
    this.setState({ qty_additional_users: selectedValue.value });
  }

  renderPurchaseForm() {
    const {
      pricing_plan_id, plan_id, additional_users_plan_id,
      qty_additional_users, subscription,
    } = this.state;
    const { purchaseButtonLabel, authToken } = this.props;
    const { invoice } = this.state;
    const purchaseBtnLabel = purchaseButtonLabel || 'Purchase';

    let invoiceForm;

    if (!invoice) {
      APIRequest.get({
        resource: '/v1/subscription/invoice',
        data:     {
          pricing_plan_id,
          plan_id,
          additional_users_plan_id,
          qty_additional_users,
        },
      }).end((err, res) => {
        this.setState(res.body);
      });
    }

    if (invoice) {
      invoiceForm = invoice.items.map((i) => {
        const klass = i.amount < 0 ? 'text-green' : '';

        return (
          <tr key={uuidv4()}>
            <td className="text-breakable">
              <p className={klass}>{i.description}</p>
              { i.trial && <small className="text-grey">{i.trial}</small> }
            </td>
            <td className={classNames('text-right', klass)}>
              <NumberFormat value={i.price} decimalScale={2} fixedDecimalScale displayType="text" prefix="$" />
            </td>
          </tr>
        );
      });
      invoiceForm.push(
        <tr key={uuidv4()}>
          <td className="text-breakable">
            <p className="lead font-weight-bold">Total Charge Today</p>
            {subscription && subscription.in_trial && (
              <p>
                <small className="text-grey">
                  Trialing until
                  {' '}
                  {Moment(subscription.trial_end).format('LL')}
                </small>
              </p>
            )}
          </td>
          <td className="text-right">
            <p className="lead font-weight-bold">
              <NumberFormat value={invoice.total} decimalScale={2} fixedDecimalScale displayType="text" prefix="$" />
            </p>
          </td>
        </tr>,
      );
    } else {
      invoiceForm = (
        <tr>
          <td><p>Loading</p></td>
          <td className="text-right">
            <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" className="mr5" />
          </td>
        </tr>
      );
    }

    return (
      <div className="billing">
        <div className="row">
          <div className="col-lg-7 mb15">
            <div className="card p30 mb30">
              <h2 className="mb10">Confirm Your Selection</h2>
              <table className="table table-flush mb20">
                <tbody>
                  {invoiceForm}
                </tbody>
              </table>
              { invoice && (
                <CardForm
                  authToken={authToken}
                  planId={plan_id}
                  pricing_plan_id={pricing_plan_id}
                  additional_users_plan_id={additional_users_plan_id}
                  qty_additional_users={qty_additional_users}
                  subscription={subscription}
                  onSuccess={this.onSuccess}
                  onCancel={this.onCancel}
                  purchaseButtonLabel={purchaseBtnLabel}
                />
              )}
            </div>
          </div>
          <div className="col-lg-5">
            <div className="card p30 mb15">
              <h4 className="mb10">Completely risk-free</h4>
              <p className="text-grey">
                If you’re not happy,
                {' '}
                <a href="mailto:help@getbrokerkit.com" target="_blank" rel="noopener noreferrer">let us know.</a>
                {' '}
                Cancel any time during your trial period, and there will be no cost.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPlan(plan) {
    const { frequency, plans, annual } = this.state;
    const oppositeFrequency = frequency === 'monthly' ? 'yearly' : 'monthly';

    const oppositeFrequencyPlan = plans[oppositeFrequency].find((p) => p.order === plan.order);

    let from;
    let to;

    if (frequency === 'annual') {
      from = plan.monthly_price;
      to = oppositeFrequencyPlan.monthly_price;
    } else {
      from = oppositeFrequencyPlan.monthly_price;
      to = plan.monthly_price;
    }

    return (
      <div>
        <h1 className="text-black">
          <CountUp
            prefix="$"
            duration={1}
            decimal="."
            decimals={2}
            start={from}
            end={to}
          />
        </h1>
        { annual && (
          <div>
            <p>
              <NumberFormat value={plan.price.toFixed(2)} displayType="text" prefix="$" suffix=" / year" />
            </p>
            <p className="text-green">
              Save
              {' '}
              <NumberFormat value={plan.savings.toFixed(2)} displayType="text" prefix="$" />
            </p>
          </div>
        )}
      </div>
    );
  }

  renderPlans() {
    const {
      subscription, frequency, plans, annual, qty_additional_users,
    } = this.state;
    const { title } = this.props;
    const activeSubscription = subscription && subscription.status === 'active';

    const isCurrentPlan = (plan) => {
      if (!subscription) return false;
      return plan.id === subscription.pricing_plan_id;
    };

    const renderLimit = (limit, unit = '') => (limit != null ? `${limit.toLocaleString()} ${unit}` : 'Unlimited');

    const pickPlanButton = (plan, klass = 'btn-secondary') => {
      if (isCurrentPlan(plan)) {
        return <button type="button" className={classNames('btn disabled', klass)}>Current Plan</button>;
      }

      return (
        <button
          type="button"
          className={classNames('btn', klass)}
          onClick={() => {
            this.onChoosePlan(plan);
          }}
        >
          Select Plan
        </button>
      );
    };

    const showPlanPicker  = typeof (annual) !== 'undefined';
    const monthlySelected = showPlanPicker && !annual;
    const annualSelected  = showPlanPicker && annual;

    return (
      <div>
        <div className="mb20 text-center">
          {title && <h1 className="text-center mb10">{title}</h1>}
          <div className="btn-group" role="group">
            <strong className="p10">Pick one: </strong>
            <button
              type="button"
              onClick={() => {
                this.setState({
                  annual:    false,
                  frequency: 'monthly',
                });
              }}
              className={classNames(
                'btn btn-wide',
                monthlySelected ? 'btn-green' : 'btn-outline-green',
              )}
            >
              {monthlySelected && (
                <FontAwesomeIcon
                  icon={['fas', 'fa-check']}
                  className="mr10"
                />
              )}
              Monthly billing
            </button>
            <button
              type="button"
              onClick={() => {
                this.setState({ annual: true, frequency: 'yearly' });
              }}
              className={classNames(
                'btn btn-wide',
                annualSelected ? 'btn-green' : 'btn-outline-green',
              )}
            >
              {annualSelected && (
                <FontAwesomeIcon
                  icon={['fas', 'fa-check']}
                  className="mr10"
                />
              )}
              Annual billing
            </button>
          </div>
        </div>

        {showPlanPicker && (
          <div className="card">
            <table className="table table-responsive mb0">
              <tbody>
                <tr>
                  <td className="br bg-grey-lightest">
                    {activeSubscription && (
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          this.setState({ view: 'subscription' });
                        }}
                      >
                        <FontAwesomeIcon
                          icon={['fas', 'fa-chevron-left']}
                          className="mr5"
                        />
                        {' '}
                        Cancel and go back
                      </button>
                    )}
                  </td>
                  {plans[frequency].map((plan) => (
                    <td
                      key={plan.id}
                      width="30%"
                      className={`text-center ${
                        plan.popular ? 'pb10' : 'pb0'
                      } pb0 br`}
                      style={
                        !plan.popular ? { verticalAlign: 'top' } : {}
                      }
                    >
                      <h5>{plan.name.split(' ')[0]}</h5>
                      {plan.popular && (
                        <p className="mt5">
                          <span className="badge badge-green">
                            Most Popular Plan
                          </span>
                        </p>
                      )}
                    </td>
                  ))}
                </tr>

                <tr>
                  <th className="br bg-grey-lightest">
                    <h4 className="mt10">Monthly price</h4>
                  </th>

                  {plans[frequency].map((plan) => (
                    <td
                      key={plan.id}
                      width="30%"
                      className="text-center br"
                    >
                      {this.renderPlan(plan)}
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="br bg-grey-lightest bt">
                    Setup fee
                  </td>
                  {plans[frequency].map((plan) => (
                    <td
                      key={plan.id}
                      width="30%"
                      className="text-center br"
                    >
                      <NumberFormat
                        value={plan.setup_fee.toFixed(2)}
                        displayType="text"
                        prefix="$"
                      />
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="br bg-grey-lightest" />
                  {plans[frequency].map((plan) => (
                    <td
                      key={plan.id}
                      width="30%"
                      className="text-breakable text-center br"
                    >
                      {(plan.popular
                        && pickPlanButton(plan, 'btn-success'))
                        || pickPlanButton(plan)}
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="br bg-grey-lightest bt">
                    Suited For
                  </td>
                  {plans[frequency].map((plan) => (
                    <td
                      key={plan.id}
                      width="30%"
                      className="text-center br"
                    >
                      {plan.suited_for}
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="br bg-grey-lightest bt">
                    User seats included
                  </td>
                  {plans[frequency].map((plan) => (
                    <td
                      key={plan.id}
                      width="30%"
                      className="text-center br"
                    >
                      {renderLimit(plan.users_limit)}
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="br bg-grey-lightest bt">
                    MLS import queries included
                  </td>
                  {plans[frequency].map((plan) => (
                    <td
                      key={plan.id}
                      width="30%"
                      className="text-center br"
                    >
                      {renderLimit(plan.search_queries_limit)}
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="br bg-grey-lightest bt">
                    Generative AI request tokens
                  </td>
                  {plans[frequency].map((plan) => (
                    <td
                      key={plan.id}
                      width="30%"
                      className="text-center br"
                    >
                      {renderLimit(plan.ai_tokens_limit)}
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="br bg-grey-lightest bt">
                    Text message segments per month
                    <br />
                    included (image/video MMS text segments
                    <br />
                    will count as 3 segments)
                  </td>
                  {plans[frequency].map((plan) => (
                    <td
                      key={plan.id}
                      width="30%"
                      className="text-center br"
                    >
                      {renderLimit(plan.sms_limit)}
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="br bg-grey-lightest bt">
                    Videos per month included
                  </td>
                  {plans[frequency].map((plan) => (
                    <td
                      key={plan.id}
                      width="30%"
                      className="text-center br"
                    >
                      {renderLimit(plan.videos_limit)}
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="br bg-grey-lightest bt">
                    Emails per month included
                  </td>
                  {plans[frequency].map((plan) => (
                    <td
                      key={plan.id}
                      width="30%"
                      className="text-center br"
                    >
                      {renderLimit(plan.emails_limit)}
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="br bg-grey-lightest bt">
                    Voice call minutes to the US or Canada per month
                    <br />
                    included
                  </td>
                  {plans[frequency].map((plan) => (
                    <td
                      key={plan.id}
                      width="30%"
                      className="text-center br bb"
                    >
                      {renderLimit(
                        plan.call_minutes_limit,
                        'minutes',
                      )}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="br bg-grey-lightest bt">
                    Call recording minutes included
                  </td>
                  {plans[frequency].map((plan) => (
                    <td
                      key={plan.id}
                      width="30%"
                      className="text-center br bb"
                    >
                      {renderLimit(
                        plan.recording_minutes_limit,
                        'minutes',
                      )}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="br bg-grey-lightest bt">
                    Bulk messaging limit
                  </td>
                  {plans[frequency].map((plan) => (
                    <td
                      key={plan.id}
                      width="30%"
                      className="text-center br bb"
                    >
                      {renderLimit(plan.bulk_message_frequency_limit)}
                      {' '}
                      bulk messages of
                      {' '}
                      <br />
                      {' '}
                      up to
                      {' '}
                      {renderLimit(plan.bulk_message_size_limit)}
                      {' '}
                      contacts
                      {' '}
                      <br />
                      {' '}
                      within
                      {' '}
                      {bulkMessageLookbackPeriod}
                      {' '}
                      days
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="br bg-grey-lightest bt">
                    Recruiting leads included
                  </td>
                  {plans[frequency].map((plan) => (
                    <td
                      key={plan.id}
                      width="30%"
                      className="text-center br bb"
                    >
                      {renderLimit(plan.leads_limit)}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="br bg-grey-lightest bt">
                    Retention team members included
                  </td>
                  {plans[frequency].map((plan) => (
                    <td
                      key={plan.id}
                      width="30%"
                      className="text-center br bb"
                    >
                      {renderLimit(plan.agents_limit)}
                    </td>
                  ))}
                </tr>
                <tr className="plan_details">
                  <td className="br bg-grey-lightest bt">
                    Plan Details
                  </td>
                  {plans[frequency].map((plan) => (
                    <td
                      key={plan.id}
                      width="30%"
                      className="br bb text-wrap"
                      dangerouslySetInnerHTML={{
                        __html: plan.plan_details,
                      }}
                    />
                  ))}
                </tr>
                <tr>
                  <td colSpan="4">
                    <h4 className="m-3">Add-Ons</h4>
                  </td>
                </tr>

                <tr>
                  <td colSpan="4">
                    <div className="form-group form-inline">
                      <label
                        htmlFor="additional_users"
                        className="label mr-3"
                      >
                        Number of Additional Users:
                      </label>
                      <AdditionalUsersSelect
                        id="additional_users"
                        className="mr-3"
                        value={qty_additional_users}
                        onChange={this.handleQtyAdditionalUsersSelect}
                        backspaceRemoves={false}
                      />

                      {subscription && (
                        <button
                          type="button"
                          onClick={this.onChooseAdditionalUsers}
                          className="btn btn-success"
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="br bg-grey-lightest bt">
                    Monthly fee to add Admin/Staff
                    <br />
                    user accounts within a pricing plan tier
                  </td>
                  {plans[frequency].map((plan) => (
                    <td
                      key={plan.id}
                      width="30%"
                      className="text-center br"
                    >
                      <NumberFormat
                        value={plan.additional_users_price.toFixed(2)}
                        displayType="text"
                        prefix="$"
                      />
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="br bg-grey-lightest bt">
                    Usage fee for months where
                    <br />
                    the text limit is exceeded
                  </td>
                  <td width="30%" colSpan="3" className="text-center">
                    $14.99 per additional block of 1,000 text segments
                  </td>
                </tr>

                <tr>
                  <td className="br bg-grey-lightest bt">
                    Usage fee for months where
                    <br />
                    the email limit is exceeded
                  </td>
                  <td width="30%" colSpan="3" className="text-center">
                    $7.99 per additional block of 15,000 emails
                  </td>
                </tr>

                <tr>
                  <td className="br bg-grey-lightest bt">
                    Usage fee for months where
                    <br />
                    the voice minute limit is exceeded
                  </td>
                  <td width="30%" colSpan="3" className="text-center">
                    $7.49 per additional block of 300 call minutes
                  </td>
                </tr>

                <tr>
                  <td className="br bg-grey-lightest bt">
                    Usage fee for voice recordings
                    <br />
                    per minute (12 month retention period)
                  </td>
                  <td width="30%" colSpan="3" className="text-center">
                    $7.49 per additional block of 600 call recording minutes
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }

  renderSubscription() {
    const { subscription, chargedCard, updatedCard } = this.state;
    const { message, onboard, authToken } = this.props;

    return (
      <div>
        { chargedCard && (
          <div className="alert alert-success mb30">
            { message || (
              <span>
                Thanks! Your Brokerkit account is now active and ready for action.
                <a href="/" className="btn btn-success ml10">Back to Dashboard</a>
              </span>
            )}
          </div>
        )}

        { updatedCard && (
          <div className="alert alert-success mb30">
            <span>
              Thanks! Your payment source has been updated.
              <a href="/" className="btn btn-success ml10">Back to Dashboard</a>
            </span>
          </div>
        )}

        <div className="row">
          <div className="col-lg-7 mb15">
            <div className="card">
              <div className="card-block p30">
                <h4 className="mb30">Subscription</h4>
                <p className="mb5">
                  {subscription.name}
                  {' '}
                  <span className="badge badge-green ml5">
                    <FontAwesomeIcon icon={['fas', 'fa-check']} className="mr5" />
                    ACTIVE
                  </span>
                </p>
                <p className="text-grey mb15">
                  Started on
                  {' '}
                  {Moment(subscription.started_at).format('LL')}
                  .
                </p>
                <button type="button" onClick={() => { this.setState({ view: 'plans' }); }} className="btn btn-secondary">Change Plan</button>
              </div>
            </div>
          </div>
          <div className="col-lg-5 mb15">
            <div className="card">
              <div className="card-block p30">
                <h5 className="mb20">Card Details</h5>
                <UpdateCardForm
                  authToken={authToken}
                  planId={subscription.plan_id}
                  pricing_plan_id={subscription.pricing_plan_id}
                  subscription={subscription}
                  onSuccessPaymentCard={this.onSuccessPaymentCard}
                  onCancel={this.onCancel}
                />
              </div>
            </div>
          </div>
        </div>

        { !onboard && (
          <div className="row">
            <div className="col-lg-12 mb15">
              <div className="card">
                <div className="card-block p30">
                  <h4 className="mb30">Usage Stats</h4>
                  <div className="row">
                    <SmsStats />
                    <EmailStats />
                    <CallStats />
                    <RecordingStats />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { hasError, loading, view } = this.state;

    let body;

    if (hasError) {
      return (
        <div className="card p40 text-center">
          <div className="mb30">
            <h2>Oh no, looks like we had an issue.</h2>
          </div>
          <p className="lead">Please try again, or refresh the page. Our site admins have been notified and we&apos;ll have this fixed shortly.</p>
        </div>
      );
    }

    if (!loading) {
      switch (view) {
        case 'subscription':
          body = this.renderSubscription();
          break;
        case 'plans':
          body = this.renderPlans();
          break;
        case 'cardform':
          body = this.renderPurchaseForm();
          break;
        default:
          body = this.renderPlans();
          break;
      }
    }

    return (
      !loading && (
        <div>
          {body}
        </div>
      )
    );
  }
}

export default BillingApp;
