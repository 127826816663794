import autoBind            from 'react-autobind';
import React               from 'react';
import PropTypes           from 'prop-types';
import classNames          from 'classnames';
import update              from 'immutability-helper';
import { animateScroll }   from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import filesize             from 'filesize';

import { checkFeatureFlag }  from '~/helpers/FeatureFlagChecker';
import AppModal              from './app_modal';
import BulkActions           from '~/actions/bulk_actions';
import APIRequest            from '~/lib/api_request';
import Select                from '~/components/forms/select';
import RecipientItem         from '~/components/modals/recipients/recipient_item';
import SmsTextEditor         from '~/components/forms/HtmlEditors/SmsTextEditor';
import ErrorMessage          from '~/components/forms/ErrorMessage';
import SmsTemplateSelect     from '~/components/forms/sms_template_select';
import VerifiedTextingWarning from '~/components/warnings/VerifiedTextingWarning';
import CheckSmsUsage         from '~/components/CheckSmsUsage';
import ScheduleButton        from '~/components/drawers/lead_drawer/forms/ScheduleForm/ScheduleButton';

const MAX_MEDIA_SIZE = 5120000; // 5MB
const charsLimit = 1600;
const limitError = ['You have exceeded your limit of maximum characters allowed.'];
const { currentTeam } = Rails.helpers;

class SendSMSModal extends React.Component {
  isRestrictionForUnverifiedNumbersEnabled = checkFeatureFlag(process.env.BLOCK_UNVERIFIED_NUMBERS_FLAG);

  constructor(props) {
    super(props);

    const { currentUser } = Rails.helpers;
    const smsNumber = _lodash.first(currentUser.sms_numbers);

    this.state = {
      loading:           false,
      loadingBulkLimits: false,
      bulkLimitsReached: false,
      loadingRecipients: false,
      renderContent:     false,
      submitting:        false,
      confirming:        false,
      disabled:          false,
      warningAccepted:   false,
      media:             {},
      sms:               {
        from:      smsNumber ? smsNumber.service_number : '',
        body:      '',
        media_url: null,
        segments:  0,
      },
      recipients:                 [],
      recipientIDs:               [],
      numbers:                    [],
      errors:                     {},
      visibleRecipients:          10,
      showVerifiedTextingWarning: false,
      isLimitExceeded:            false,
      schedule:                   { customOptions: { timezone: currentUser.timezone } },
      scheduleTextsAllowed:       checkFeatureFlag(process.env.ALLOW_SCHEDULE_TEXTS_FLAG),
      limitAlertAllowed:          false,
    };

    this.containerRef = React.createRef();

    autoBind(this);
  }

  componentDidMount() {
    this.loadRecipients();
    this.setState({
      limitAlertAllowed: checkFeatureFlag(process.env.ALLOW_ALERT_ON_TEXT_LIMIT_FLAG),
    });
  }

  onWarningUpdated = (isLimitExceeded) => {
    this.setState({ isLimitExceeded });
  }

  setSchedule = (newSchedule) => {
    this.setState((prevState) => ({
      schedule: { ...prevState.schedule, ...newSchedule },
    }));
  };

  handleFromChange = (opt) => {
    this.setState((prevState) => {
      const fromValue = opt?.value || null;
      const showWarning = fromValue ? !opt.verified_texting && this.isRestrictionForUnverifiedNumbersEnabled : false;

      return {
        sms:                        { ...prevState.sms, from: fromValue },
        showVerifiedTextingWarning: showWarning,
      };
    });
  }

  handleBodyChange = (body, segments) => {
    this.setState((prevState) => ({ sms: { ...prevState.sms, body, segments } }), () => {
      this.validateBody();
    });
  }

  handleMediaUrlChange = (media) => {
    let media_url;

    if (media.type === 'image') {
      media_url = media.small_media_url;
    }

    this.setState((prevState) => ({
      media,
      sms: {
        ...prevState.sms,
        media_url,
      },
    }), () => {
      this.validateVideoFile();
    });
  }

  handleNext = (e) => {
    e.preventDefault();

    const errors = this.validate();

    if (_lodash.size(errors) === 0) {
      this.setState({ confirming: true });
    }
  }

  loadMore = () => {
    this.setState((prev) => ({ visibleRecipients: prev.visibleRecipients + 10 }), () => {
      this.scrollToBottom();
    });
  }

  scrollToBottom = () => {
    animateScroll.scrollToBottom({
      containerId: 'recipients-table',
    });
  }

  loadSmsNumberOptions = () => {
    this.setState({ loading: true }, () => {
      APIRequest.get({
        resource: '/v1/sms_numbers/team',
      }).end((error, response) => {
        if (!error) {
          const options = response.body.data.map((n) => ({
            value:            n.service_number,
            label:            `${n.user_name} - ${n.formatted_number}`,
            verified_texting:  n.verified_texting,
          }));

          const { sms: { from } } = this.state;
          const defaultOption = options.find((option) => option.value === from);
          const showWarning = defaultOption && !defaultOption.verified_texting && this.isRestrictionForUnverifiedNumbersEnabled;

          this.setState({
            numbers:                    options,
            loading:                    false,
            showVerifiedTextingWarning: showWarning,
          });
        }
      });
    });
  }

  loadRecipients = () => {
    this.setState({ loadingRecipients: true }, () => {
      const {
        searchData,
        bulkSelecting,
        selectedItems,
        unSelectedItems,
        blastTeam,
      } = this.props;

      let selectedLeads = Object.values(selectedItems);
      const unselectedLeads = Object.values(unSelectedItems);

      let smsData;

      if (bulkSelecting) {
        selectedLeads = 'all';
        smsData = { ...searchData, ids: 'all', unselected_ids: _lodash.map(unselectedLeads, 'id') };
      } else if (blastTeam) {
        selectedLeads = 'blast';
        smsData = { ...searchData, ids: 'blast' };
      } else {
        smsData = { ...searchData, ids: _lodash.map(selectedLeads, 'id') };
      }

      APIRequest.get({
        resource: '/v1/lead_bulk_emails/recipients',
        data:     smsData,
      }).end((error, response) => {
        this.setState({ loadingRecipients: false }, () => {
          if (!error) {
            const recipients = response.body;

            this.setState({
              recipients,
              recipientIDs: _lodash.map(recipients, 'id'),
            }, () => {
              this.validateBulkLimits();
            });
          }
        });
      });
    });
  }

  validateBulkLimits = () => {
    const { recipients } = this.state;

    this.setState({ loadingBulkLimits: true }, () => {
      APIRequest.get({
        resource: '/v1/bulk_actions/validate_limits',
        data:     {
          count_of_contacts: recipients.length,
        },
      }).end((error, response) => {
        this.setState({ loadingBulkLimits: false }, () => {
          if (!error) {
            const { bulk_message_frequency_limit, bulk_message_size_limit, reached_limits } = response.body;

            this.setState({
              bulkLimitsReached:         reached_limits,
              bulkMessageFrequencyLimit: bulk_message_frequency_limit || 'unlimited',
              bulkMessageSizeLimit:      bulk_message_size_limit || 'unlimited',
              renderContent:             true,
            }, () => {
              if (!reached_limits) this.loadSmsNumberOptions();
            });
          }
        });
      });
    });
  }

  acceptWarning = () => {
    this.setState({ warningAccepted: true });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const $modal = $(this.appModal.modal);

    this.setState({ submitting: true }, () => {
      const {
        searchData,
        blastTeam,
        reloadLeads,
      } = this.props;
      const { recipientIDs, sms, schedule } = this.state;

      const smsData = { ids: recipientIDs, ...searchData };

      APIRequest.post({
        resource: '/v1/lead_bulk_sms',
        data:     { ...smsData, ...sms, schedule },
      }).end((error, response) => {
        this.setState({ submitting: true }, () => {
          if (!error) {
            $modal.modal('hide');

            BulkActions.createAction('email');

            if (!blastTeam) {
              reloadLeads();
            }

            const notifyMessage = schedule?.selectedSchedule ? 'Your text message is scheduled to be sent' : 'Your Text Message is in the send queue and will go out shortly';
            GlobalContainer.notify(notifyMessage, 'success');
          } else {
            GlobalContainer.notify(response?.body?.error || response.text, 'error');
            this.setState({ submitting: false });
          }
        });
      });
    });
  }

  handleRecipientSelect = (leadId, selected) => {
    const { recipientIDs } = this.state;

    const leadIdx = recipientIDs.indexOf(leadId);
    const leadSelected = leadIdx > -1;

    if (leadSelected) {
      if (!selected) {
        this.setState((prevState) => {
          const nextIds = [...prevState.recipientIDs];
          nextIds.splice(leadIdx, 1);

          return { recipientIDs: nextIds };
        });
      }
    } else if (selected) {
      this.setState((prevState) => ({ recipientIDs: [...prevState.recipientIDs, leadId] }));
    }
  }

  validateVideoFile = () => {
    const { media } = this.state;

    if (media.type === 'image') return;

    const media_size = media?.animated_thumbnail ? media.gif_byte_size : media.thumbnail_byte_size;
    let error;

    if (media_size > MAX_MEDIA_SIZE) {
      error = [`File is too large (${filesize(media_size)}). Allowed ${filesize(MAX_MEDIA_SIZE)}.`];
    }

    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        media: error,
      },
    }));
  }

  removeAttachment = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      media: null,
      sms:   {
        ...prevState.sms,
        media_url: null,
      },
    }));
  }

  renderRecipients = () => {
    const { recipients, loadingRecipients, visibleRecipients } = this.state;

    const leadRows = [];

    recipients.slice(0, visibleRecipients).forEach((lead, idx) => {
      leadRows.push(
        <RecipientItem
          lead={lead}
          key={`lead-item-${lead.id}`}
          handleRecipientSelect={this.handleRecipientSelect}
        />,
      );
    });

    const containerStyle = {
      maxHeight: '400px',
      overflowY: 'scroll',
    };

    if (leadRows.length === 0 && loadingRecipients) {
      leadRows.push = (
        <tr>
          <td className="text-center">Loading recipients...</td>
        </tr>
      );
    }

    const containerClass = classNames(GlobalContainer.product());

    return (
      <div className={containerClass} style={containerStyle} id="recipients-table">
        <table className="table table-hover main leads-table mb0 no-border">
          <tbody>
            {leadRows}
          </tbody>
        </table>
      </div>
    );
  }

  renderConfirming = () => {
    const {
      submitting, sms, recipientIDs, loadingRecipients, visibleRecipients, schedule, scheduleTextsAllowed,
    } = this.state;

    const count = recipientIDs.length;
    const takeOffHeader = count === 1 ? '1 person' : `${count.toLocaleString('en-US')} people`;

    const sendButtonClass = classNames('btn btn-primary', { disabled: submitting });

    if (loadingRecipients) {
      return (
        <>
          <div className="modal-body" style={{ minHeight: '50vh' }}>
            <h4 className="text-center">
              Loading Recipients
              {' '}
              <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
            </h4>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="modal-body">
          <h4 className="text-center">Prepare for blast...</h4>
          <div className="mt30 mb30 text-center">
            <h2>{takeOffHeader}</h2>
            <p className="lead text-grey">will receive</p>
            <div
              className="lead card text-white bg-primary text-message mt30 mb30"
              style={{ borderRadius: '25px', maxWidth: '50%', margin: 'auto' }}
            >
              <div className="card-body">
                <p className="card-text text-white text-left" style={{ whiteSpace: 'pre-wrap' }}>
                  {sms.media_url && (
                    <img alt="" src={sms.media_url} style={{ maxWidth: '100%' }} />
                  )}
                  {sms.body}
                </p>
              </div>
            </div>

            {this.renderRecipients()}

            {visibleRecipients < count
              && <button onClick={this.loadMore} type="button" className="btn btn-primary mt-3">Load more ...</button>}
          </div>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              this.setState({ confirming: false });
            }}
          >
            Back
          </button>
          <div className="btn-group" role="group" aria-label="email-group">
            <button type="submit" className={sendButtonClass} disabled={submitting}>
              {submitting ? 'sending...' : 'Confirm Blast'}
            </button>
            {scheduleTextsAllowed && (
              <ScheduleButton
                schedule={schedule}
                setSchedule={this.setSchedule}
                popoverPlacement="top"
                containerRef={this.containerRef}
                disabled={submitting}
              />
            )}
          </div>
        </div>
      </>
    );
  }

  renderAttachment = () => {
    const { media, sms } = this.state;
    const { media_url } = sms;

    if (!media_url) return null;

    const name = media?.name || media?.title;

    return (
      <div className="alert alert-success p10 text-left mb5" key={name}>
        <div className="pull-right">
          <a
            href="#remove-attachment"
            onClick={this.removeAttachment}
          >
            <FontAwesomeIcon icon={['far', 'fa-times']} />
          </a>
        </div>
        <div className="text-break">
          <FontAwesomeIcon icon={['far', 'fa-file']} className="mr10" />
          <a href={media_url} target="_blank" rel="noopener noreferrer">
            {name}
          </a>
        </div>
      </div>
    );
  }

  renderBulkVallidationError = () => {
    const { bulkMessageFrequencyLimit, bulkMessageSizeLimit } = this.state;
    const bulkMessageLookbackPeriod = process.env.BULK_MESSAGE_LOOKBACK_PERIOD / 24;

    const trialText = (
      <>
        while on a trial. Your limits will be increased at the end of the
        {' '}
        trial to those of your selected pricing plan seen
        {' '}
        <a href="/billing" target="_blank" rel="noopener noreferrer" className="alert-link">here.</a>
      </>
    );

    const mainText = (
      <>
        on your pricing plan. Please upgrade your pricing plan
        {' '}
        <a href="/billing" target="_blank" rel="noopener noreferrer" className="alert-link">here</a>
        {' '}
        to increase your limits.
      </>
    );

    return (
      <div className="p20 text-center">
        <div className="alert alert-danger" role="alert">
          Bulk messaging actions can only be triggered on up to
          {' '}
          {bulkMessageSizeLimit}
          {' '}
          contacts up to
          {' '}
          {bulkMessageFrequencyLimit}
          {' '}
          times within
          {' '}
          {bulkMessageLookbackPeriod}
          {' '}
          days
          {' '}
          {currentTeam.trial ? trialText : mainText}
        </div>
      </div>
    );
  }

  renderBody = () => {
    const {
      confirming,
      bulkLimitsReached,
      loadingBulkLimits,
      renderContent,
    } = this.state;

    if (loadingBulkLimits) {
      return (
        <div className="text-center p40">
          <FontAwesomeIcon icon="far fa-spinner" pulse className="fa-lg" />
        </div>
      );
    }

    if (bulkLimitsReached) {
      return this.renderBulkVallidationError();
    }

    if (renderContent && confirming) {
      return this.renderConfirming();
    }

    if (renderContent && !confirming) {
      return this.renderContent();
    }

    return null;
  }

  applyTemplate(template) {
    const newState = update(this.state, {
      sms: {
        body:      { $set: template.body },
        media_url: { $set: template.media_url },
      },
      media: {
        name: { $set: template.media_name },
      },
      templateBody: { $set: template.body },
      errors:       { $set: {} },
    });

    this.setState(newState);
  }

  validate() {
    const VALIDATABLE_FIELDS = ['from', 'body'];
    const { sms } = this.state;
    const errors = {};

    VALIDATABLE_FIELDS.forEach((field) => {
      if (!sms[field]) errors[field] = ["Can't be empty"];
    });

    if (sms.body && charsLimit < sms.body.length) {
      errors.body = limitError;
    }

    this.setState({ errors });

    return errors;
  }

  validateBody() {
    const { sms } = this.state;
    const lengthCondition = sms.body && charsLimit < sms.body.length;

    const newState = update(this.state, {
      errors: {
        body: { $set: lengthCondition ? limitError : null },
      },
      disabledButton: { $set: !!lengthCondition },
    });

    this.setState(newState);
  }

  renderContent = () => {
    const {
      loading, submitting, disabledButton, errors, numbers, sms,
      templateBody, showVerifiedTextingWarning, warningAccepted,
      isLimitExceeded, recipientIDs, limitAlertAllowed,
    } = this.state;
    const sendButtonClass = classNames('btn btn-primary', { disabled: submitting });

    const selectedItemsCount = recipientIDs.length;

    return (
      <>
        <div className="modal-body">
          <CheckSmsUsage
            segmentsCount={sms.segments}
            selectedItemsCount={selectedItemsCount}
            onWarningAccepted={this.acceptWarning}
            onWarningUpdated={this.onWarningUpdated}
          />

          {showVerifiedTextingWarning && <VerifiedTextingWarning />}

          <div className="form-group mb15">
            <label htmlFor="sender-from" className="d-block">From</label>
            <Select
              className={errors.from ? 'has-error' : ''}
              placeholder="Enter sender number"
              value={sms.from}
              isLoading={loading}
              options={numbers}
              openOnFocus
              onChange={this.handleFromChange}
            />
          </div>

          <div className="form-group mb15">
            <SmsTextEditor
              mms={!!sms.media_url}
              className={errors.body ? 'tiny-mce has-error' : ''}
              placeholder="Enter your text message"
              value={sms.body}
              onChange={this.handleBodyChange}
              replaceValue={templateBody}
              handleMediaUrlChange={this.handleMediaUrlChange}
              clearReplaceValue={() => {
                this.setState({
                  templateBody: null,
                });
              }}
            />
            {errors.body && <ErrorMessage message={errors.body} />}
          </div>

          <div className="row m0">
            <div className="col-lg-5 p0">
              {this.renderAttachment()}
              {errors.media && <ErrorMessage message={errors.media} />}
            </div>

            <div className="col-lg-4 p0 pl0-sm-down ml-auto">
              <SmsTemplateSelect
                body={sms.body}
                media_url={sms.media_url}
                media_name={sms.media_name}
                applyTemplate={this.applyTemplate}
                validateSmsTemplate={this.validate}
              />
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal">
            Cancel
          </button>
          <button type="button" className={sendButtonClass} onClick={this.handleNext} disabled={disabledButton || showVerifiedTextingWarning || (limitAlertAllowed && isLimitExceeded && !warningAccepted)}>
            Next
          </button>
        </div>
      </>
    );
  }

  render() {
    const {
      records, blastTeam,
    } = this.props;
    const {
      submitting, confirming, sms,
    } = this.state;

    const spinnerImg = <FontAwesomeIcon icon="far fa-spinner" pulse />;
    let modalHeader = confirming ? 'Confirming Bulk Text' : 'Bulk Text';

    if (blastTeam) {
      modalHeader = (
        <>
          {confirming ? 'Confirming ' : ''}
          Mass Text
          <small> to All team agents</small>
        </>
      );
    }

    return (
      <>
        <AppModal ref={(appModal) => (this.appModal = appModal)} dialogClass="modal-dialog modal-lg">
          <form method="POST" onSubmit={this.handleSubmit}>
            <div className="modal-header">
              <h5 className="modal-title" id="appModalLabel">
                {modalHeader}
                {submitting && spinnerImg}
              </h5>

              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            { this.renderBody() }
          </form>
        </AppModal>
      </>
    );
  }
}

SendSMSModal.defaultProps = {
  blastTeam:       false,
  bulkSelecting:   false,
  records:         {},
  selectedItems:   {},
  searchData:      {},
  table:           {},
  unSelectedItems: {},
};

SendSMSModal.propTypes = {
  blastTeam:       PropTypes.bool,
  bulkSelecting:   PropTypes.bool,
  records:         PropTypes.shape({}),
  selectedItems:   PropTypes.shape({}),
  searchData:      PropTypes.shape({}),
  table:           PropTypes.shape({}),
  unSelectedItems: PropTypes.shape({}),
};

export default SendSMSModal;
