import autoBind            from 'react-autobind';
import React               from 'react';
import { v4 as uuidv4 }    from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Table            from 'react-bootstrap/Table';
import Tooltipable      from '~/components/effects/tooltipable';
import ClickableTooltip from '~/components/effects/clickable_tooltip';

import brokerbit                from '~/lib/brokerbit';
import MlsServiceRequest        from '~/lib/mls_service_request';
import SearchQueryActions       from '~/actions/search_query_actions';
import SearchQueryStore         from '~/stores/search_query_store';
import SubscriptionLimitActions from '~/actions/subscription_limit_actions';
import SubscriptionLimitStore   from '~/stores/subscription_limit_store';

const { currentUser, currentTeam } = Rails.helpers;

class MlsImportTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingSearchQueries: false,
      loading:              false,
      searchQueries:        [],
      subscriptionLimits:   {},
      submittingQueryIds:   [],
    };

    autoBind(this);
  }

  componentDidMount() {
    this.searchQueryStoreListener = SearchQueryStore.addListener(this.onSearchQueryStoreChange);
    this.subscriptionLimitStoreListener = SubscriptionLimitStore.addListener(this.onSubscriptionLimitStoreChange);

    setTimeout(() => {
      SearchQueryActions.loadSearchQueries();
      SubscriptionLimitActions.loadSubscriptionLimits();
    });
  }

  componentWillUnmount() {
    if (this.searchQueryStoreListener) this.searchQueryStoreListener.remove();
    if (this.subscriptionLimitStoreListener) this.subscriptionLimitStoreListener.remove();
  }

  handleAddSearchQuery(e) {
    e.preventDefault();

    const searchQueryNewUri = GlobalContainer.productUri('/search_queries/new');

    browserHistory.push(searchQueryNewUri);
  }

  handleSearchQueriesReachedLimit(e) {
    e.preventDefault();

    brokerbit.dialogBox({
      message:    'You are currently at the limit of import queries available with your pricing plan. Please reach out to the Brokerkit Customer Success team to request an upgrade.',
      okLabel:    'Request Upgrade',
      okCallback: (ok) => {
        window.open('https://support.getbrokerkit.com/hc/en-us/requests/new', '_blank');
      },
    });
  }

  handleQueryImport(query) {
    this.setState((prevState) => ({
      submittingQueryIds: [...prevState.submittingQueryIds, query.id],
    }));

    MlsServiceRequest.post({
      resource: '/imports',
      data:     {
        team_name:       currentTeam.name,
        email:           currentUser.email,
        user_id:         currentUser.id,
        user_name:       currentUser.name,
        search_query_id: query.id,
      },
    }).end((error, response) => {
      this.setState(
        (prevState) => ({
          loading:            false,
          submittingQueryIds: prevState.submittingQueryIds.filter((id) => id !== query.id),
        }),
        () => {
          if (error) {
            GlobalContainer.notify(response.body.message, 'error');
          } else {
            GlobalContainer.notify(
              'Your import has started successfully. We will send you an email about the import results.',
              'success',
            );
          }
        },
      );
    });
  }

  handleScheduleQueryUpdates(query) {
    MlsServiceRequest.post({
      resource: '/import_schedules',
      data:     {
        team_name:       currentTeam.name,
        email:           currentUser.email,
        user_id:         currentUser.id,
        user_name:       currentUser.name,
        search_query_id: query.id,
      },
    }).end((error, response) => {
      this.setState({ loading: false }, () => {
        if (!error) {
          SearchQueryActions.loadSearchQueries();

          if (error) {
            GlobalContainer.notify(
              response.body.message,
              'error',
            );
          } else {
            GlobalContainer.notify(
              'Monthly Import Query Updates Triggered',
              'success',
            );
          }
        }
      });
    });
  }

  handleUnscheduleQueryUpdates(query) {
    MlsServiceRequest.delete({
      resource: `/import_schedules/${query.import_schedule.id}`,
    }).end((error, response) => {
      this.setState({ loading: false }, () => {
        if (!error) {
          SearchQueryActions.loadSearchQueries();

          GlobalContainer.notify(
            'Monthly Import Query Updates Ended',
            'success',
          );
        }
      });
    });
  }

  handleEditQuery(query) {
    const search_query_edit_uri = GlobalContainer.productUri(`/search_queries/${query.id}/edit`);

    browserHistory.push(search_query_edit_uri);
  }

  handleDeleteQuery(query) {
    if (_lodash.isEmpty(query)) return;

    brokerbit.confirmBox({
      message:  'Are you sure you want to delete this Query?',
      callback: (ok) => {
        if (ok) {
          SearchQueryActions.deleteSearchQuery(query);
        }
      },
    });
  }

  onSearchQueryStoreChange() {
    const searchQueryStoreState = SearchQueryStore.getState();
    const { loadingSearchQueries, searchQueries } = searchQueryStoreState;

    this.setState({
      loadingSearchQueries,
      searchQueries,
    });
  }

  onSubscriptionLimitStoreChange() {
    const subscriptionLimitStoreState = SubscriptionLimitStore.getState();
    const { loading, subscriptionLimits } = subscriptionLimitStoreState;

    this.setState({
      loading,
      subscriptionLimits,
    });
  }

  displayRowCount(count = 0) {
    if (count === 'calculating') {
      return <FontAwesomeIcon icon="far fa-spinner" pulse />;
    }

    return count;
  }

  displayStatusBadge(status) {
    if (!status) {
      return null;
    }

    let badgeClass = '';

    switch (status) {
      case 'never_run':
        badgeClass = 'default';
        break;
      case 'in_queue':
        badgeClass = 'info';
        break;
      case 'in_progress':
        badgeClass = 'info';
        break;
      case 'succeed':
        badgeClass = 'success';
        status = 'success';
        break;
      case 'failed':
        badgeClass = 'danger';
        break;
      case 'canceled':
        badgeClass = 'danger';
        break;
      case 'terminated':
        badgeClass = 'danger';
        break;
      default:
        badgeClass = 'default';
    }

    return (
      <span className={`badge badge-outline-${badgeClass}`}>
        {_lodash.upperCase(status)}
      </span>
    );
  }

  renderScheduleButton(query) {
    if (!query.import_schedule) {
      return (
        <Tooltipable text="Trigger Monthly Query Updates">
          <button
            type="button"
            className="btn btn-outline-danger btn-sm mr5"
            onClick={(e) => {
              e.preventDefault();

              this.handleScheduleQueryUpdates(query);
            }}
          >
            <FontAwesomeIcon icon={['far', 'fa-clock']} />
          </button>
        </Tooltipable>
      );
    }

    if (query.import_schedule) {
      return (
        <Tooltipable text="End Monthly Query Updates">
          <button
            type="button"
            className="btn btn-outline-success btn-sm mr5"
            onClick={(e) => {
              e.preventDefault();

              this.handleUnscheduleQueryUpdates(query);
            }}
          >
            <FontAwesomeIcon icon={['far', 'fa-clock']} />
          </button>
        </Tooltipable>
      );
    }

    return null;
  }

  renderSearchQueriesRows() {
    const { searchQueries, submittingQueryIds } = this.state;

    return searchQueries.map((query, index) => {
      const searchQueryRow = (
        <tr key={uuidv4()}>
          <td style={{ whiteSpace: 'break-spaces' }}>{query.name}</td>
          <td className="text-center">
            {this.displayStatusBadge(query.status_for_last_job)}
            {query.error_message_for_last_job && (
              <>
                <span
                  id="error-message-icon"
                  className="text-warning ml-2"
                >
                  <FontAwesomeIcon
                    icon={['far', 'fa-exclamation-triangle']}
                  />
                </span>

                <ClickableTooltip
                  text={query.error_message_for_last_job}
                  placement="right"
                  target="error-message-icon"
                />
              </>
            )}
          </td>
          <td className="text-center">
            {this.displayRowCount(query.data.count)}
          </td>
          <td className="text-center">
            {query.last_run
              && Moment(query.last_run).format('l - LT')}
          </td>
          <td className="text-center">
            <Tooltipable text="Trigger Query Import">
              <button
                type="button"
                className="btn btn-secondary btn-sm mr5"
                disabled={submittingQueryIds.includes(query.id)}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleQueryImport(query);
                }}
              >
                {submittingQueryIds.includes(query.id) ? (
                  <FontAwesomeIcon
                    icon={['far', 'fa-spinner']}
                    pulse
                  />
                ) : (
                  <FontAwesomeIcon icon={['far', 'fa-download']} />
                )}
              </button>
            </Tooltipable>

            {this.renderScheduleButton(query)}

            <Tooltipable text="Edit Query">
              <button
                type="button"
                className="btn btn-secondary btn-sm mr5"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleEditQuery(query);
                }}
              >
                <FontAwesomeIcon icon={['far', 'fa-pencil']} />
              </button>
            </Tooltipable>

            <Tooltipable text="Delete Query">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleDeleteQuery(query);
                }}
              >
                <FontAwesomeIcon icon={['far', 'fa-trash-alt']} />
              </button>
            </Tooltipable>
          </td>
        </tr>
      );

      return searchQueryRow;
    });
  }

  renderSearchQueriesTable() {
    const { loadingSearchQueries, searchQueries, loading } = this.state;

    if (loadingSearchQueries || loading) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon="far fa-spinner" pulse size="lg" />
        </div>
      );
    }

    if (searchQueries.length === 0) {
      return <div className="text-grey-dark text-center">There are no MLS Import Queries defined. Please add one.</div>;
    }

    return (
      <Table responsive borderless striped>
        <tbody>
          <tr>
            <th>Name</th>
            <th className="text-center">Status</th>
            <th className="text-center">
              <Tooltipable placement="top" text="This count is updated each time you save the search and it can change through time based on MLS data changes">
                Count
              </Tooltipable>
            </th>
            <th className="text-center">Last Run</th>
            <th className="text-center">Actions</th>
          </tr>
          {this.renderSearchQueriesRows()}
        </tbody>
      </Table>
    );
  }

  renderAddSearchQueryLink() {
    const {
      loadingSearchQueries,
      searchQueries,
      loading,
      subscriptionLimits,
    } = this.state;

    if (loading || loadingSearchQueries) {
      return (
        <button id="add-search-query-btn" type="button" className="btn btn-success disabled">Add Import Query</button>
      );
    }

    if (subscriptionLimits.search_queries_limit !== null
        && subscriptionLimits.search_queries_limit <= searchQueries.length) {
      return (
        <button id="add-search-query-btn" type="button" onClick={this.handleSearchQueriesReachedLimit} className="btn btn-success">Add Import Query</button>
      );
    }

    return (
      <button type="button" id="add-search-query-btn" className="btn btn-success" onClick={this.handleAddSearchQuery}>
        Add Import Query
      </button>
    );
  }

  render() {
    return (
      <div className="card-block">
        <div className="row">
          <div className="col-6 my-auto">
            <h4>MLS Import Queries</h4>
          </div>
          <div className="col-6">
            <div className="pull-right">
              {this.renderAddSearchQueryLink()}
              <ClickableTooltip
                text={(
                  <>
                    Learn more about the MLS Import feature
                    {' '}
                    <a className="text-green" href="https://info.getbrokerkit.com/knowledge/hc/en-us/articles/4464878235533-how-to-configure-the-relitix-integration-in-brokerkit" target="_blank" rel="noopener noreferrer">here.</a>
                  </>
                )}
                placement="bottom"
                target="add-search-query-btn"
              />
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-12">
            {this.renderSearchQueriesTable()}
          </div>
        </div>
      </div>
    );
  }
}

export default MlsImportTab;
