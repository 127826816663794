import React, {
  useState,
  useEffect,
} from 'react';
import ReactDOM             from 'react-dom';
import { PropTypes }        from 'prop-types';
import { Editor }           from '@tinymce/tinymce-react';
import nl2br                from 'nl2br';
import pluralize            from 'pluralize';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';

import { SegmentedMessage } from 'sms-segments-calculator';
import { checkFeatureFlag } from '~/helpers/FeatureFlagChecker';
import ClickableTooltip     from '~/components/effects/clickable_tooltip';
import MmsAttachmentModal   from '~/components/modals/attachments/MmsAttachmentModal';
import AssistModal          from '~/components/modals/AiAssist/Modal';
import VideosModal          from '~/components/modals/videos/VideosModal';
import {
  TINYMCE_SCRIPT_PATH,
  SMS_TEXT_EDITOR_PLUGINS,
  SMS_TEXT_EDITOR_TOOLBAR,
} from '~/constants/TinyMCE';
import { initWebSpellChecker } from '~/lib/web_spell_checker';

const tooltipText = (
  <>
    Please Note on Text Segment Usage:

    <ul className="pl-4">
      <li>Text messages are limitied to 160 characters and messages &gt; 160 characters will be split across multiple segments. Certain characters like emojis 😁 can increase your count faster.</li>
      <li>MMS segments with image or videos count as 3 segments.</li>
      <li>
        Please be sure to monitor your text messaged segment usage
        {' '}
        <a href="/billing" target="_blank" rel="noopener noreferrer" className="text-white">here</a>
        .
      </li>
    </ul>
  </>
);

const SmsTextEditor = ({
  id,
  value,
  mms,
  className,
  placeholder,
  replaceValue,
  clearReplaceValue,
  onChange,
  handleMediaUrlChange,
}) => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState('');
  const [charsCount, setCharsCount] = useState(0);
  const [segments, setSegments] = useState(0);
  const [hasURL, setHasURL] = useState(false);
  const [showVideoWarning, setShowVideoWarning] = useState(false);

  useEffect(() => {
    if (replaceValue) {
      const text = formatContent(replaceValue);
      setContent(text);
      clearReplaceValue();
    }
  }, [replaceValue, clearReplaceValue]);

  const formatContent = (text) => nl2br(text);

  const countTextChars = (text) => {
    let count = 0;
    if (typeof text === 'string') {
      count = new SegmentedMessage(text).numberOfCharacters;
    }
    return count;
  };

  const countTextSegments = (text) => {
    let newSegments = 0;
    if (typeof text === 'string') {
      const textWithoutNonBreakingSpaces = text.replace(/\u00A0/g, ' ');
      newSegments = new SegmentedMessage(textWithoutNonBreakingSpaces).segmentsCount;
    }
    return newSegments;
  };

  const handleEditorChange = (newContent, editor) => {
    let text = '';
    text = editor.getContent({ format: 'text' });
    const textHasURL = /https?:\/\/[^\s]+/.test(text);

    setContent(newContent);
    setSegments(countTextSegments(text));
    setCharsCount(countTextChars(text));
    setHasURL(textHasURL);
    onChange(text);
  };

  const handleOnInit = (_event, editor) => {
    setLoading(false);

    if (value) {
      const text = formatContent(value);
      editor.setContent(text, { format: 'text' });
    }

    initWebSpellChecker(editor.iframeElement);
  };

  return (
    <>
      {showVideoWarning && (
        <div className="alert alert-info my-2">
          It looks like you&apos;re trying to add a video to your text — great choice! Please
          {' '}
          <a href="/billing" target="_blank" rel="noopener noreferrer" className="text-primary">upgrade your pricing plan</a>
          {' '}
          to enable this feature.
        </div>
      )}

      <label htmlFor="sms-text-body" className="d-block">Body</label>

      <div className={className}>
        {loading && (
          <div className="text-center">
            <FontAwesomeIcon icon="far fa-spinner" pulse className="mr5" />
            {' '}
            Loading
          </div>
        )}

        <Editor
          id={id}
          tinymceScriptSrc={TINYMCE_SCRIPT_PATH}
          scriptLoading={{ async: true }}
          value={content}
          onEditorChange={handleEditorChange}
          onInit={handleOnInit}
          init={{
            placeholder,
            license_key:                   'gpl',
            plugins:                       SMS_TEXT_EDITOR_PLUGINS,
            toolbar:                       SMS_TEXT_EDITOR_TOOLBAR,
            toolbar_mode:                  'wrap',
            branding:                      false,
            menubar:                       false,
            min_height:                    350,
            relative_urls:                 false,
            remove_script_host:            false,
            document_base_url:             Rails.baseUrl,
            entity_encoding:               'raw',
            content_style:                 'body { font-family: Arial; font-size: 13px; }',
            paste_as_text:                 true,
            contextmenu:                   false,
            paste_remove_styles_if_webkit: false,
            statusbar:                     false,
            newline_behavior:              'linebreak',
            forced_root_block:             'div',
            setup:                         (editor) => {
              editor.ui.registry.addIcon('videoIcon', '<span class="fas fa-video-plus" />');
              editor.ui.registry.addIcon('usersIcon', '<span class="fas fa-users" />');

              editor.ui.registry.addButton('images', {
                icon:     'image',
                tooltip:  'Insert Image',
                onAction: () => {
                  ReactDOM.render(
                    <MmsAttachmentModal
                      containerID="secondary-modal"
                      modalClass="modal modal-overlay"
                      dialogClass="modal-dialog"
                      handleMediaUrlChange={handleMediaUrlChange}
                    />,
                    document.getElementById('secondary-modal'),
                  );
                },
              });

              editor.ui.registry.addButton('videos', {
                icon:     'videoIcon',
                tooltip:  'Insert Video',
                onAction: () => {
                  const isVideoTextingFeatureEnabled = checkFeatureFlag(process.env.VIDEO_TEXT_FEATURE_FLAG);

                  if (isVideoTextingFeatureEnabled) {
                    ReactDOM.render(
                      <VideosModal
                        containerID="secondary-modal"
                        modalClass="modal modal-overlay"
                        dialogClass="modal-dialog modal-lg"
                        editor={editor}
                        sms
                      />, document.getElementById('secondary-modal'),
                    );
                  } else {
                    setShowVideoWarning(true);
                  }
                },
              });

              editor.ui.registry.addButton('ai-assist', {
                icon:     'ai',
                tooltip:  'AI Assist',
                onAction: () => {
                  ReactDOM.render(
                    <AssistModal
                      containerID="ai-assist-modal"
                      modalClass="modal modal-overlay"
                      dialogClass="modal-dialog"
                      editor={editor}
                      mode="text"
                    />,
                    document.getElementById('ai-assist-modal'),
                  );
                },
              });

              editor.ui.registry.addMenuButton('variables', {
                icon:    'usersIcon',
                text:    'Variables...',
                tooltip: 'Variables',
                fetch:   (callback) => {
                  const items = [
                    { type: 'menuitem', text: 'Lead First Name',     onAction: () => { editor.insertContent('{{firstName}}'); } },
                    { type: 'menuitem', text: 'Lead Last Name',      onAction: () => { editor.insertContent('{{lastName}}'); } },
                    { type: 'menuitem', text: 'Lead Full Name',      onAction: () => { editor.insertContent('{{fullName}}'); } },
                    { type: 'menuitem', text: 'Lead Company',        onAction: () => { editor.insertContent('{{company}}'); } },
                    { type: 'menuitem', text: "Agent's Volume",      onAction: () => { editor.insertContent('{{Volume}}'); } },
                    { type: 'menuitem', text: 'Referrer First',      onAction: () => { editor.insertContent('{{referrerFirst}}'); } },
                    { type: 'menuitem', text: 'Referrer Last',       onAction: () => { editor.insertContent('{{referrerLast}}'); } },
                    { type: 'menuitem', text: 'Referrer Full Name',  onAction: () => { editor.insertContent('{{referrerName}}'); } },
                    { type: 'menuitem', text: 'Sender First Name',   onAction: () => { editor.insertContent('{{senderFirst}}'); } },
                    { type: 'menuitem', text: 'Sender Last Name',    onAction: () => { editor.insertContent('{{senderLast}}'); } },
                    { type: 'menuitem', text: 'Sender Full Name',    onAction: () => { editor.insertContent('{{senderFull}}'); } },
                    { type: 'menuitem', text: 'Sender Company',      onAction: () => { editor.insertContent('{{senderCompany}}'); } },
                    { type: 'menuitem', text: 'Sender Landing Page', onAction: () => { editor.insertContent('{{LandingPage}}'); } },
                    { type: 'menuitem', text: 'Sender Phone Number', onAction: () => { editor.insertContent('{{senderPhone}}'); } },
                  ];

                  callback(items);
                },
              });
            },
          }}
        />
      </div>

      {!loading && (
        <div className="mt-3">
          <>
            <span id="segments-count">
              {pluralize('Characters', charsCount, true)}
              {' '}
              -
              {' '}
              {mms ? '3 MMS Segments' : pluralize('SMS Segments', segments, true)}
            </span>

            <ClickableTooltip
              placement="right"
              text={tooltipText}
              innerClassName="html-tooltip-inner"
              target="segments-count"
            />
          </>

          {hasURL && (
            <div className="alert alert-info" role="alert">
              Note: The hyperlink in this text will be shortened as it is delivered.
            </div>
          )}

          {segments > 1 && (
            <div className="alert alert-warning mt-2" role="alert">
              This is a multi segment message and text billing is
              per segment
            </div>
          )}
        </div>
      )}
    </>
  );
};

SmsTextEditor.defaultProps = {
  id:                    '',
  value:                '',
  mms:                   false,
  className:             '',
  placeholder:           'Enter text or insert ...',
  replaceValue:          null,
  handleMediaUrlChange:  () => false,
  clearReplaceValue:     () => false,
  onChange:              () => false,
};

SmsTextEditor.propTypes = {
  id:                   PropTypes.string,
  value:                PropTypes.string,
  mms:                  PropTypes.bool,
  className:             PropTypes.string,
  placeholder:           PropTypes.string,
  replaceValue:          PropTypes.string,
  handleMediaUrlChange:  PropTypes.func,
  clearReplaceValue:     PropTypes.func,
  onChange:              PropTypes.func,
};

export default SmsTextEditor;
